/* HomePage.css - Dark Mode Styles with Responsive Design */
.homepage-container {
  width: 100%;
  max-width: 100vw;
  text-align: left;
  margin: 0;
  margin-bottom: 30px;
  color: #f0f0f0;
  background-color: #282c34;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 16px;
}

@media (max-width: 768px) {
  .homepage-container {
    padding: 8px;
  }
}

.content-container {
  display: flex;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  padding: 0 10px;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .content-container {
    padding: 0 4px;
  }
}

.main-content-header {
  width: 100%;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  color: #f0f0f0;
  margin: -10px 0 -20px -10px;
  padding: 10px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .main-content-header {
    margin: -5px 0 -10px -5px;
    padding: 5px;
  }
}

.main-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  padding: 0 10px;
}

@media (max-width: 768px) {
  .main-content {
    gap: 10px;
    padding: 0 4px;
  }
}

.input-container,
.button-container,
.generated-text-container {
  margin: 10px;
  max-width: 95%;
  width: 100%;
}

@media (max-width: 768px) {
  .input-container,
  .button-container,
  .generated-text-container {
    margin: 5px 2px;
    max-width: 98%;
  }
}

/* WriteTab specific styles */
.MuiBox-root .input-container {
  margin: 8px 0;
  width: 100%;
}

@media (max-width: 768px) {
  /* Override MUI Box padding */
  .MuiBox-root {
    padding: 0 !important;
  }

  /* Target the main WriteTab container */
  .homepage-container {
    padding: 4px !important;
  }

  /* Target the controls section */
  .MuiBox-root .controls-section {
    margin: 0 !important;
    padding: 4px !important;
  }

  /* Target the chat and text input columns */
  .MuiBox-root > .MuiBox-root {
    padding: 0 !important;
    margin: 0 !important;
  }

  /* Remove extra padding from forms */
  .MuiFormControl-root {
    margin: 4px 0 !important;
  }

  /* Maximize width of inputs */
  .input-container {
    margin: 4px 0 !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  /* Target chat interface container */
  .custom-chat-interface {
    width: 100% !important;
    margin: 0 !important;
    border-radius: 4px !important;
  }

  /* Override any existing margins */
  .content-container,
  .main-content,
  .input1-container,
  .button-container,
  .generated-text-container {
    margin: 0 !important;
    padding: 0 !important;
    max-width: 100% !important;
  }
}

.input-label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #f0f0f0;
  text-align: left;
}

.text-input {
  width: 100%;
  min-height: 200px;
  max-height: 600px;
  overflow-y: auto;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #444;
  background-color: #282c34;
  color: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  box-sizing: border-box;
  resize: none;
}

/* Scrollbar styles for Firefox */
.text-input {
  scrollbar-width: thin;
  scrollbar-color: #666 #444;
}

/* Scrollbar styles for Webkit browsers */
.text-input::-webkit-scrollbar {
  width: 8px;
}

.text-input::-webkit-scrollbar-track {
  background: #444;
  border-radius: 4px;
}

.text-input::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 4px;
  border: 2px solid #444;
}

.text-input::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

/* Scrollbar styles for Webkit browsers */
.drawer-textarea::-webkit-scrollbar {
  width: 8px;
}

.drawer-textarea::-webkit-scrollbar-track {
  background: #444;
  border-radius: 4px;
}

.drawer-textarea::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 4px;
  border: 2px solid #444;
}

.drawer-texarea::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

.generated-text {
  text-align: left;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 20px;
  background-color: #333;
  color: #fff;
  word-wrap: break-word; /* Ensure long text wraps */
}

/* Responsive Design for smaller screens */
/* Responsive Design for smaller screens */
@media screen and (max-width: 768px) {
  .homepage-container,
  .content-container,
  .main-content,
  .input1-container,
  .input-container,
  .button-container,
  .generated-text-container {
    padding: 10px;
    margin: 0;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    box-sizing: border-box;
  }

  .text-input {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
  }

  .tab-headers {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    padding: 0 5px;
  }

  .tab-headers::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, Opera */
  }

  .tab-headers li {
    flex: 0 0 auto;
    white-space: nowrap;
  }

  .tab-content {
    padding: 10px;
    width: 100%;
    max-width: 100vw;
    box-sizing: border-box;
  }

  .icon-container {
    margin-right: 10px;
  }
}

.tab-headers {
  list-style-type: none; /* Removes the default bullet points from the list */
  padding: 0; /* Removes default padding from the list */
  display: flex; /* Arranges the list items (tabs) in a row instead of a column */
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
}

.tab-headers li {
  cursor: pointer; /* Changes the mouse cursor to indicate clickable items */
  padding: 10px; /* Adds space inside each tab header around the content */
  background-color: #444; /* Sets the background color of each tab header */
  margin-right: 2px; /* Adds a small space to the right of each tab header */
}

.tab-headers li.active {
  background-color: #2a2d36; /* Changes the background color for the active tab */
  border-bottom: 1px solid rgb(73, 128, 237); /* Adds a border to the bottom of the active tab */
}

.tab-content {
  border: 0px solid #5a4747; /* Adds a border around the tab content area */
  padding: 20px; /* Adds space inside the content area around the actual content */
}

.prompt-container {
  position: relative;
  margin: 0;
  padding: 0;
}

.prompt-icons {
  position: absolute;
  right: 100px;
  top: 100px;
  display: flex;
  gap: 10px;
  z-index: 10;
}

.icon-button {
  cursor: pointer;
  color: #7890aa; /* Adjust the color */
  margin-right: 10px;
  font-size: 20px; /* Adjust the size */
}

.icon-button:hover {
  color: #f17516; /* Adjust hover color */
}

.icon-container {
  position: absolute;
  right: 0px; /* Adjust as needed */
  top: -1px; /* Adjust as needed */
  display: flex;
  gap: 5px; /* Space between icons */
}

.icon-button-generate {
  cursor: pointer;
  color: rgb(237, 241, 18); /* Adjust the color */
  margin-right: 10px;
  font-size: 23px; /* Adjust the size */
}

.icon-button-generate:hover {
  color: #f17516; /* Adjust hover color */
}

.icon-button.highlighted {
  color: #414541; /* Example: change to green when highlighted */
}

.icon-button-generate.highlighted {
  color: #414541; /* Example: change to green when highlighted */
}

.icon-button-large.highlighted {
  color: #414541; /* Example: change to green when highlighted */
}

.select-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: left; /* Adjust this as needed for alignment */
  padding-bottom: 50px;
  margin-left: -18px;
}

.positioned-typography {
  position: absolute;
  bottom: 285px;
  left: 30px;
  /* Other styles */
}

.drawer-whatsnew-textarea {
  width: 180px;
  background-color: #282c34;
  color: #fff;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 10px;
  resize: none;
  box-sizing: border-box;
  position: relative;
  height: 60vh;
  font-size: 12px;
  text-align: left; /* For text alignment */
}

.drawer-textarea, .drawer-whatsnew-textarea {
  width: 100%;
  background-color: #282c34;
  color: #fff;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 10px;
  resize: none;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 30px;
  height: 40vh;
  font-size: 12px;
  text-align: left; /* For text alignment */
}

/* Scrollbar styles for Webkit browsers */
.drawer-whatsnew-textarea::-webkit-scrollbar {
  width: 8px;
}

.drawer-whatsnew-textarea::-webkit-scrollbar-track {
  background: #444;
  border-radius: 4px;
}

.drawer-whatsnew-textarea::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 4px;
  border: 2px solid #444;
}

.drawer-whatsnew-textarea::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

@media (max-width: 600px) { /* Adjust breakpoint as needed */
  .MuiDrawer-paper {
    width: 100%; /* Make drawer take full width on smaller screens */
  }

  .positioned-typography, .drawer-whatsnew-textarea, .drawer-textarea {
    position: static; /* Adjust positioning for smaller screens */
    width: auto; /* Let the width be automatically adjusted */
    margin-bottom: 20px; /* Add some margin to the bottom */
  }
}

.radioGroup {
  display: flex;
  justify-content: center; /* Center align horizontally */
  align-items: center; /* Center align vertically */
  margin: -10px 0; /* Margin around the group */
}

.radioLabel {
  font-size: 16px;
  color: #333; /* Example color */
}

.radioControl {
  color: rgb(49, 13, 181); /* Color of the radio button */
}

/* Style for the FormLabel component */
.formLabel {
  font-size: 18px;
  color: #666;
  text-align: center;
  margin-bottom: 10px;
}

.url-input-container {
  width: calc(50% - 40px);
  min-height: 10px;
  padding: 0px;
  border: 0px solid #ccc;
  margin-left: 0px; /* center the container */
  margin-right: 0px;
  margin-bottom: -40px;
  padding-left: 10px;
  padding-bottom: 10px;
}

.url-input-container textarea {
  width: 100%;
  padding: px;
  font-size: 14px; /* comfortable reading size */
  border: 1px solid #444;
  box-sizing: border-box; /* includes padding in width calculation */
}

.summary-style-container {
  width: 100%;
  margin-bottom: 20px;
  margin-left: 20px;
}

@media screen and (max-width: 1000px) {
  .url-input-container {
    width: 91%;
    height: 430px;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 10px;
    padding-right: 20px;
  }
  .summary-style-container {
    width: 100%;
    padding-bottom: 20px;
    padding-top: 0px;
  }
}

/* Flex container for URL input and button */
.flex-container {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Same as the dropzone width */
  margin-bottom: 20px;
}

.pdf-display {
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #666 #444;
  padding: 20px;
  background-color: #333; /* Dark background color */
  min-height: 100%;
  height: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

/* Custom scrollbars */
.pdf-display::-webkit-scrollbar {
  width: 8px;
}

.pdf-display::-webkit-scrollbar:horizontal {
  height: 8px;
}

.pdf-display::-webkit-scrollbar-track {
  background-color: #444;
}

.pdf-display::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 4px;
}

.pdf-display::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

.pdf-display::-webkit-scrollbar-corner {
  background-color: #444;
}

/* Style for the PDF page */
.react-pdf__Page {
  margin: 10px auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: white;
}

/* Container for proper page centering */
.react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

canvas.react-pdf__Page__canvas {
  width: 100%; /* Makes the canvas width responsive to the container */
  height: auto; /* Keeps the aspect ratio of the page */
  width: auto;
  margin-bottom: 30px;
  display: block; /* Ensures no inline spacing */
}

.myTextField .MuiInputBase-input {
  background-color: #444;
  border-radius: 4px;
  color: #999;
}

.myTextField .MuiInputLabel-root {
  color: white;
}

/* Target the placeholder within the TextField */
.myTextField .MuiInputBase-input::placeholder {
  color: rgb(199, 193, 193); /* Change placeholder text color */
  opacity: 1; /* Ensure the color does not get reduced opacity */
}

/* Optionally, style the placeholder when the TextField is disabled */
.myTextField.Mui-disabled .MuiInputBase-input::placeholder {
  color: darkgray; /* Adjust color for disabled state if needed */
}

.fab-container {
  border: 3px solid #444; /* Adds a border to the container */
  padding: 15px 0px 10px 0px; /* Adds some space inside the container */
  margin: 0px; /* Adds some space outside the container */
  margin-top: 40px;
  margin-left: 10px;
  border-radius: 14px; /* Optional: Rounds the corners of the container */
  background-color: #444; /* Optional: Sets a background color for the container */
  display: flex; /* Uses flexbox to align child elements */
  flex-wrap: wrap;
  gap: 20px; /* Adds space between child elements */
  justify-content: center; /* Centers child elements horizontally */
  align-items: center; /* Centers child elements vertically */
  width: 90%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

@media (min-width: 768px) {
  .fab-container > * {
    /* Targets all direct children of fab-container */
    flex-basis: auto;
  }
}

.viewer-container {
  border: 1px solid #444; /* Adds a border to the container */
  padding: 0px 0px 30px; /* Adds some space inside the container */
  margin-top: 30px; /* Adds some space outside the container */
  margin-bottom: 50px;
  border-radius: 8px; /* Optional: Rounds the corners of the container */
  background-color: #282c34; /* Optional: Sets a background color for the container */

  gap: 20px; /* Adds space between child elements */
  justify-content: space-between; /* Centers child elements horizontally */
  align-items: center; /* Centers child elements vertically */
  width: 90%;
}

@media (max-width: 600px) {
  .viewer-container > * {
    /* Targets all direct children of fab-container */
    flex-basis: auto;
  }
}

.slider-container {
  border: 0px solid #444; /* Adds a border to the container */
  padding: 0px 0px 0px; /* Adds some space inside the container */
  margin: 0px; /* Adds some space outside the container */
  padding: 0px;
  margin-top: 10px;
  border-radius: 4px; /* Optional: Rounds the corners of the container */
  background-color: #444; /* Optional: Sets a background color for the container */
  display: flex; /* Uses flexbox to align child elements */
  flex-wrap: wrap;
  justify-content: center; /* Centers child elements horizontally */
}

.icon-button-large {
  color: #888; /* Adjust the color */
}

.icon-button-large:hover {
  color: #f17516; /* Adjust hover color */
}

.icon-large-container {
  top: -1px; /* Adjust as needed */
  display: flex;
  gap: 10px; /* Space between icons */
}

@media screen and (max-width: 768px) {
  .input-container {
    width: 100%;
    margin: auto;
  }
}

.textarea-container {
  position: relative;
  width: 90%; /* Match the width of your MyTextArea component */
  padding-left: 5%;
}

.enter-button {
  position: absolute;
  right: 7%; /* Adjust as needed */
  border-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  padding: 3px;
  padding-left: 5px;
  padding-right: 6px;
  /* Additional styling for the button */
}

/* Additional styles for hover effect - Optional */
.enter-button:hover {
  background-color: #e0e0e0; /* Slightly darker background on hover */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* Slightly larger shadow on hover */
}

.pdf-icon-button {
  padding: 10px;
  border: none;
  background-color: white; /* Change as needed */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Makes the button circular */
  width: 50px; /* Width of the button */
  height: 50px; /* Height of the button */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 1);
}

.pdf-icon-button.highlighted {
  background-color: yellow; /* Highlight color */
}

.pdf-icon-button:disabled {
  opacity: 0.5;
}

/* Additional styles for hover effect - Optional */
.pdf-icon-button:not(:disabled):hover {
  background-color: #e0e0e0; /* Slightly darker background on hover */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* Slightly larger shadow on hover */
}

.image-prompt-container {
  width: calc(50% - 40px);
  padding: 0px;
  border: 0px solid #ccc;
  margin-left: 0px; /* center the container */
  margin-right: 0px;
  margin-bottom: -40px;
  padding-left: 0px;
  padding-bottom: 10px;
}

.image-prompt-container textarea {
  width: 100%;
  padding: px;
  font-size: 14px; /* comfortable reading size */
  border: 1px solid #444;
  box-sizing: border-box; /* includes padding in width calculation */
}

@media screen and (max-width: 1024px) {
  .image-prompt-container {
    width: 91%;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 10px;
    padding-right: 20px;
  }
}

.generate-button {
  border-radius: 40px;
  font-size: 12px;
  /* Additional styling for the button */
}

.audio-upload-box {
  border: 2px dashed #666;
  border-radius: 4px;
  margin-top: -20px;
  margin-left: 10px;
  margin-bottom: 16px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 800px) {
  .audio-upload-box {
    margin-left: 10px;
    width: 90%;
  }
}

.transcript-container {
  margin: 10px;
  max-width: 800px;
  width: 100%; /* Ensure it takes up available width */
}

@media (max-width: 800px) {
  .transcript-container {
    margin-left: 10px;
    width: 105%;
  }
}

.document-list {
  margin: 10px 0;
  max-height: 200px;
  overflow-y: auto;
  padding: 0 10px;
}

.document-item {
  padding: 8px 12px;
  margin: 4px 0;
  background-color: #333;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #fff;
  font-size: 14px;
}

.document-item:hover {
  background-color: #444;
}

.document-item.selected {
  background-color: #505050;
  border-left: 3px solid #007bff;
}

.upload-controls {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 0 10px;
}

.pdf-takenotes-container {
  display: flex;
  gap: 16px;
  padding: 16px;
  width: 100%;
}

.save-chat-button {
  position: absolute;
  right: 20px;
  bottom: -50px; /* Position below the chat interface in desktop view */
  z-index: 1;
}

@media (max-width: 832px) {
  .pdf-takenotes-container {
    flex-direction: column;
    width: 100vw;
    padding: 16px 0;
    margin-left: -16px;
  }



  .save-chat-button {
    bottom: 10px; /* Adjust as needed */
    margin-right: 20px;
  }
}

@media (max-width: 832px) {
  .pdf-takenotes-container {
    flex-direction: column;
    width: 100vw;
    padding: 16px 0;
    margin-left: -16px;
  }


  .save-chat-button {
    bottom: 10px; /* Position at bottom of container in mobile view */
  }


}

/* Custom scrollbar styles - these will now be consistently applied */
.messages-scroll-container {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  contain: content;
}

.messages-scroll-container::-webkit-scrollbar {
  width: 8px;
}

.messages-scroll-container::-webkit-scrollbar-track {
  background-color: #444;
}

.messages-scroll-container::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 4px;
}

.messages-scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

.chat-textarea {
  flex: 1;
  padding: 8px 12px;
  background-color: #282c34;
  color: #fff;
  border: 1px solid #666;
  border-radius: 2px;
  resize: none;
  min-height: 40px;
  max-height: 150px;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.5;
  outline: none;
}

.chat-textarea:focus:not(:disabled) {
  border-color: #90caf9;
}

.chat-textarea:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.chat-textarea {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #444;
  }
  &::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}
/* MUI breakpoints:
  xs: 0
  sm: 600px
  md: 900px
  lg: 1200px
  xl: 1536px
*/